import React from 'react';
import { useLocation } from 'react-router-dom';
import { FormScreenWrapper, GridScreenWrapper, ServiceDrivenViewSet } from '@samc/screen-config-core';
import { CustomScreenParams } from '@samc/screen-config-core/lib/contexts/TabOverrideContext/TabOverrideContext';
import { FormView, TaskRequest, TaskResponse } from '@samc/screen-config-api';
import { useStyletron } from 'styletron-react';
import { AddAssetsOrValuationGroups } from '../AddAssetsOrValuationGroups/AddAssetsOrValuationGroups';
import { BidGroupManagementGrid } from '../BidGroupManagementGrid/BidGroupManagementGrid';
import { AddBidRequestItems } from '../AddBidRequestItems/AddBidRequestItems';
import ValuerBidItemResponses from '../ValuerBidItemResponses/ValuerBidItemResponses';
import { GroupByGridWrapper } from '../../molecules/GroupByGridWrapper/GroupByGridWrapper';

// because we need to have a component we can use to watch for form submissions, we have to track
// the custom tabs in two places
const customTabs = [
  'BID_AddBidGroupItems',
  'BidGroupsTab',
  'BidGroupManagement',
  'BID_BidRequestManagement',
  'BID_AddBidRequestItems',
  'BID_ProposedValuerApprovals',
  'BID_ProposedValuerLevel2Approvals',
  'BID_RecommendedValuerApprovals',
  'BID_RecommendedValuerLevel2Approvals',
  'OpenValuerBidItemResponses',
  'ClosedValuerBidItemResponses',
  'defaultTestingTab',
];

interface CustomTabRendererParams extends CustomScreenParams {
  primaryKeyValue?: string | undefined;
  tabId: string;
  refreshViewSet: () => void;
}

const CustomTabRenderer = (params: CustomTabRendererParams): JSX.Element => {
  const { primaryKeyValue, tabId, refreshViewSet } = params;
  const [newGroupID, setNewGroupID] = React.useState<string | undefined>(primaryKeyValue);
  const [css] = useStyletron();

  const location = useLocation();
  const queryParamPrimaryKey = React.useMemo(() => {
    const locationParams = new URLSearchParams(location.search);
    return locationParams.get('primaryKeyValue') || '';
  }, [location.search]);

  const onAddGroup = React.useCallback(
    (_formView: FormView, _taskRequests: TaskRequest<Record<string, unknown>>[], response: TaskResponse[]): void => {
      if (response[0].newIds && Object.values(response[0].newIds)[0]) {
        setNewGroupID(Object.values(response[0].newIds)[0] as string);
      }
    },
    [],
  );

  switch (tabId) {
    case 'BID_AddBidGroupItems':
      return <AddAssetsOrValuationGroups {...params} primaryKeyValue={newGroupID} refreshViewSet={refreshViewSet} />;
    case 'BidGroupsTab':
      return (
        <FormScreenWrapper
          onSubmit={onAddGroup}
          formViewId="BID_BidGroupInfo"
          {...params}
          primaryKeyValue={newGroupID}
          ServiceDrivenViewSet={ServiceDrivenViewSet}
          GridScreenWrapper={GridScreenWrapper}
          className={css({ flex: 1, height: '100%' })}
        />
      );
    case 'BidGroupManagement':
      return <BidGroupManagementGrid {...params} />;
    case 'BID_ManageBidRequestItems':
      return <AddBidRequestItems {...params} className={css({ flex: 1 })} />;
    case 'BID_AddBidRequestItems':
      return <AddBidRequestItems {...params} primaryKeyValue={queryParamPrimaryKey} className={css({ flex: 1 })} />;
    case 'BID_ProposedValuerApprovals':
      return <GroupByGridWrapper {...params} domainId="BID_BidRequestItem" groupByFieldName="BidRequest_Name" />;
    case 'BID_ProposedValuerLevel2Approvals':
      return <GroupByGridWrapper {...params} domainId="BID_BidRequestItem" groupByFieldName="BidRequest_Name" />;
    case 'BID_RecommendedValuerApprovals':
      return <GroupByGridWrapper {...params} domainId="BID_BidRequestItem" groupByFieldName="BidRequest_Name" />;
    case 'BID_RecommendedValuerLevel2Approvals':
      return <GroupByGridWrapper {...params} domainId="BID_BidRequestItem" groupByFieldName="BidRequest_Name" />;
    case 'OpenValuerBidItemResponses':
      return (
        <ValuerBidItemResponses
          {...params}
          filters={[...(params.filters ?? []), "[BidRequest_BidRequestStatusCode] = 'ACTIVE'"]}
        />
      );
    case 'ClosedValuerBidItemResponses':
      return (
        <ValuerBidItemResponses
          {...params}
          filters={[...(params.filters ?? []), "[BidRequest_BidRequestStatusCode] = 'INACTIVE'"]}
        />
      );
    default:
      return <></>;
  }
};

export const renderCustomTab = (params: CustomScreenParams, refreshViewSet: () => void): JSX.Element | undefined => {
  if (!customTabs.includes(params.tabId)) return undefined;

  return <CustomTabRenderer {...params} refreshViewSet={refreshViewSet} />;
};

export default renderCustomTab;
