import React from 'react';

import { GridScreenWrapper, ServiceDrivenViewSet } from '@samc/screen-config-core';
import { EntityEntitlementContextProvider } from '@samc/screen-config-api';
import { CustomScreenParams } from '@samc/screen-config-core/lib/contexts/TabOverrideContext/TabOverrideContext';

interface GroupByGridWrapperProps extends CustomScreenParams {
  domainId: string;
  groupByFieldName: string;
}

export const GroupByGridWrapper = ({
  selectedFilterIds,
  listViewId,
  setListViewId,
  setSelectedFilterIds,
  primaryKeyValue,
  filters,
  tabId,
  tabs,
  domainId,
  groupByFieldName,
}: GroupByGridWrapperProps): React.ReactElement => {
  const tabBaselineFilterExpression = tabs[tabId].baselineFilterExpression ?? '';
  // need to make a copy because we don't want to mutate the original array
  const appendedFilters = filters ? [...filters] : [];
  if (!appendedFilters.includes(tabBaselineFilterExpression)) {
    appendedFilters.push(tabBaselineFilterExpression);
  }
  return (
    <EntityEntitlementContextProvider domainId={domainId} entityId={primaryKeyValue} targetEntitlementTypes={[]}>
      <GridScreenWrapper
        listViewId={listViewId}
        selectedFilterIds={selectedFilterIds}
        setListViewId={setListViewId}
        setSelectedFilterIds={setSelectedFilterIds}
        filters={appendedFilters}
        enableGroupByGrid
        groupByFieldName={groupByFieldName}
        ServiceDrivenViewSet={ServiceDrivenViewSet}
      />
    </EntityEntitlementContextProvider>
  );
};

export default GroupByGridWrapper;
