import * as React from 'react';
import {
  ServiceDrivenViewSet,
  GridSelectionProvider,
  getTabRedirectString,
  ViewSetSubtabChangedEvent,
  ActionOverrideContextProvider,
} from '@samc/screen-config-core';
import { ErrorBoundary } from '@samc/react-ui-core';
import { EditingProvider } from '@samc/react-ui-grid';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  HeaderContextProvider,
  RoutingWrapper,
  useHeaderContext,
  useRouterBlockerAndPromptForUnsavedChanges,
} from '@samc/react-ui-history';
import { ViewSetAction } from '@samc/screen-config-api';
import { CustomScreenParams } from '@samc/screen-config-core/lib/contexts/TabOverrideContext/TabOverrideContext';
import { decodeBase64, decodeBase64ToObject } from '../../util';
import { renderCustomTab } from '../../components/organisms/CustomTabRenderer/CustomTabRenderer';
import { CustomTriggerActions } from '../../components/organisms/CustomTriggerActions/CustomTriggerActions';
import { ViewID } from '../../static/BidEngageConstants';

interface ViewSetProps {
  triggeredAction: ViewSetAction | undefined;
  updateAction: React.Dispatch<ViewSetAction | undefined>;
  onTabChanged: (newId: string) => void;
  onSubtabChanged: (ev: ViewSetSubtabChangedEvent) => void;
  tabId?: string;
  subtabId?: string;
  portfolioId?: string;
}

export const URL_PARAMNAME_DEFAULTS = 'defaults';
const URL_PARAMNAME_FILTER = 'filter';

const ViewSetMain = (viewSetProps: ViewSetProps): React.ReactElement => {
  const { triggeredAction, updateAction, tabId, onTabChanged, subtabId, onSubtabChanged, portfolioId } = viewSetProps;
  const { id } = useParams();

  const [viewsetKey, updateViewsetKey] = React.useState<number>(1);

  const navigate = useNavigate();
  const location = useLocation();
  const { viewId } = triggeredAction ?? { viewId: '' };
  useRouterBlockerAndPromptForUnsavedChanges();
  const params = new URLSearchParams(location.search);

  const primaryKeyValue = React.useMemo(() => {
    return params.get('primaryKeyValue') || '';
  }, [location.search]);

  const handleSetPrimaryKey = React.useCallback(
    (value: string | undefined): void => {
      if (value) {
        params.set('primaryKeyValue', value);
        navigate(`${location.pathname}?${params.toString()}`);
      }
    },
    [location.pathname, location.search, navigate],
  );

  const encodedDefaultValuesJson = React.useMemo(() => params.get(URL_PARAMNAME_DEFAULTS), [params]);
  const encodedFilterExpression = React.useMemo(() => params.get(URL_PARAMNAME_FILTER), [params]);

  const defaultValues = React.useMemo(() => {
    if (!encodedDefaultValuesJson || !encodedDefaultValuesJson.length) {
      return undefined;
    }
    try {
      return decodeBase64ToObject<Record<string, unknown>>(encodedDefaultValuesJson);
    } catch {
      console.error(`Unable to decode or parse default data '${encodedDefaultValuesJson}'.`); // eslint-disable-line no-console
      return undefined;
    }
  }, [encodedDefaultValuesJson]);

  const urlFilters = React.useMemo((): string[] => {
    // return nothing if not set
    if (!encodedFilterExpression || !encodedFilterExpression.length) return [];

    const decodedExpression = decodeBase64(encodedFilterExpression);
    try {
      const filterArray = JSON.parse(decodedExpression);
      if (filterArray && Array.isArray(filterArray)) {
        return filterArray;
      }
    } catch (e) {
      // intentionally left empty
    }

    // fall back to prior behavior and return single expression in array
    return [decodedExpression];
  }, [encodedFilterExpression]);

  const refreshViewSet = React.useCallback(() => {
    return updateViewsetKey(viewsetKey + 1);
  }, [viewsetKey]);

  const customTabRender = React.useCallback(
    (customScreenParams: CustomScreenParams): JSX.Element | undefined => {
      return renderCustomTab(customScreenParams, refreshViewSet);
    },
    [viewsetKey],
  );

  return (
    <ErrorBoundary>
      <div key={viewsetKey} style={{ display: 'flex', flexDirection: 'column', flex: '1 0 auto' }}>
        {id ? (
          // key here is set this way so that when the portfolio filter is changed, it will recreate/reload the data
          <ServiceDrivenViewSet
            key={`${id}_${portfolioId}`}
            viewSetId={id}
            filters={urlFilters}
            primaryKeyValue={primaryKeyValue}
            setPrimaryKeyValue={handleSetPrimaryKey}
            defaultData={defaultValues}
            tabRenderer={customTabRender}
            initialSelectedTabId={tabId}
            onTabChanged={onTabChanged}
            parentScope={`BID_${portfolioId}`}
            initialSelectedSubtabId={subtabId}
            onSubtabChanged={onSubtabChanged}
          />
        ) : (
          <div>Please specify a ViewSet Id.</div>
        )}
        <CustomTriggerActions
          triggeredAction={triggeredAction}
          updateAction={updateAction}
          primaryKeyValue={primaryKeyValue}
          viewId={viewId}
          refreshViewSet={refreshViewSet}
        />
      </div>
    </ErrorBoundary>
  );
};

const ViewSetPageInner = (): React.ReactElement => {
  const originalLocation = useLocation();
  const { tabId: urlTabId, subtabId: urlSubtabId } = useParams();

  const navigate = useNavigate();
  const location = useLocation();
  const { tabs } = useHeaderContext();

  const [triggeredAction, updateAction] = React.useState<ViewSetAction>();

  const onTabIdChanged = React.useCallback(
    (newId: string, userRequested?: boolean) => {
      if (newId === urlTabId) return; // do nothing if id doesn't change

      navigate(
        getTabRedirectString({
          originalLocation,
          oldTabId: urlTabId,
          oldSubtabId: urlSubtabId,
          newTabId: newId,
          newSubtabId: undefined, // should clear it on tab change
        }),
        { replace: !userRequested },
      );
    },
    [navigate, originalLocation, urlSubtabId, urlTabId],
  );

  const onSubtabIdChanged = React.useCallback(
    (ev: ViewSetSubtabChangedEvent) => {
      const { newId, userRequested } = ev;

      if (newId === urlSubtabId) return; // do nothing if id doesn't change

      navigate(
        getTabRedirectString({
          originalLocation,
          oldTabId: urlTabId,
          oldSubtabId: urlSubtabId,
          newTabId: urlTabId,
          newSubtabId: newId,
        }),
        { replace: !userRequested },
      );
    },
    [navigate, originalLocation, urlSubtabId, urlTabId],
  );

  const onTabIndexChanged = React.useCallback(
    (newIndex: number, userRequested: boolean) => {
      if (!tabs) return;

      const tab = tabs[newIndex];
      if (!tab) return;

      onTabIdChanged(tab.id, userRequested);
    },
    [onTabIdChanged, tabs],
  );

  const params = new URLSearchParams(location.search);

  const getPortfolioId = (): string | null => {
    if (params.has('portfolioId')) return params.get('portfolioId');
    if (params.has('PortfolioId')) return params.get('PortfolioId');
    return null;
  };

  const portfolioId = getPortfolioId();

  const overriddenViewIds = [
    ViewID.BidRequestModal,
    ViewID.SetProposedValuerForInternalReview,
    ViewID.SetProposedValuerForExternalReview,
    ViewID.RequestClientApprovalValuerSelections,
    ViewID.ApproveProposedValuer,
    ViewID.DeclineProposedValuer,
    ViewID.ApproveProposedValuerLevel2,
    ViewID.DeclineProposedValuerLevel2,
    ViewID.ResubmitProposedValuersforApproval,
    ViewID.SendInvitesToProposedValuers,
    ViewID.ApproveRecommendedValuer,
    ViewID.DeclineRecommendedValuer,
    ViewID.ApproveRecommendedValuerLevel2,
    ViewID.DeclineRecommendedValuerLevel2,
    ViewID.SetRequestToOnHold,
  ];

  return (
    <EditingProvider>
      <ActionOverrideContextProvider
        value={overriddenViewIds.reduce(
          (o, v) => ({ ...o, [v]: (item: ViewSetAction): void => updateAction(item) }),
          {},
        )}
      >
        <GridSelectionProvider>
          <RoutingWrapper onTabChanged={onTabIndexChanged}>
            <ViewSetMain
              subtabId={urlSubtabId}
              onSubtabChanged={onSubtabIdChanged}
              tabId={urlTabId}
              onTabChanged={onTabIdChanged}
              triggeredAction={triggeredAction}
              updateAction={updateAction}
              portfolioId={portfolioId ?? ''}
            />
          </RoutingWrapper>
        </GridSelectionProvider>
      </ActionOverrideContextProvider>
    </EditingProvider>
  );
};

export const ViewSetPage = (): React.ReactElement => {
  return (
    <HeaderContextProvider>
      <ViewSetPageInner />
    </HeaderContextProvider>
  );
};

export default ViewSetPage;
