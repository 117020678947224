import React from 'react';
import { ViewSetAction, ViewType } from '@samc/screen-config-api';
import { AddBidRequestModalWrapper } from '../AddBidRequestModal/AddBidRequestModalWrapper';
import { BidEngageDomains, ViewID } from '../../../static/BidEngageConstants';
import { CustomActionHandler } from '../CustomActionHandler/CustomActionHandler';
import { BidRequestItem } from '../../../BidEngageApi/models/BidRequestItem';
import { BidRequest } from '../../../BidEngageApi/models/BidRequest';

interface CustomTriggerActionsParams {
  triggeredAction?: ViewSetAction;
  viewId?: string;
  primaryKeyValue?: string;
  refreshViewSet: () => void;
  updateAction: (a: ViewSetAction | undefined) => void;
}

export const CustomTriggerActions = (params: CustomTriggerActionsParams): JSX.Element => {
  const { triggeredAction, viewId, primaryKeyValue, refreshViewSet, updateAction } = params;
  return (
    <>
      {triggeredAction && viewId === ViewID.BidRequestModal && (
        <AddBidRequestModalWrapper
          viewSetId={ViewID.BidRequestModal}
          primaryKeyValue={primaryKeyValue}
          refreshViewSet={refreshViewSet}
          updateAction={updateAction}
        />
      )}
      {triggeredAction && viewId === ViewID.SetProposedValuerForInternalReview && (
        <CustomActionHandler<BidRequestItem>
          updateAction={updateAction}
          refreshViewSet={refreshViewSet}
          actionFlag="SendProposedValuersForInternalTeamReview"
          requestIdentifier="SetProposedValuerForInternalReview Modal"
          dialogDetail="Are you sure you want to Send the Proposed Valuer(s) for Internal Team Review?"
          dialogTitle="Send Proposed Valuers for Internal Review"
          domainId={BidEngageDomains.BidRequestItem}
          viewId={ViewID.BID_BidRequestItemsList}
          viewType={ViewType.Grid}
        />
      )}
      {triggeredAction && viewId === ViewID.SetProposedValuerForExternalReview && (
        <CustomActionHandler<BidRequestItem>
          updateAction={updateAction}
          refreshViewSet={refreshViewSet}
          actionFlag="SendProposedValuersForExternalTeamReview"
          requestIdentifier="SetProposedValuerForExternalReview Modal"
          dialogDetail="Are you sure you want to Send the Proposed Valuer(s) for External Review?"
          dialogTitle="Send Proposed Valuers for External Review"
          domainId={BidEngageDomains.BidRequestItem}
          viewId={ViewID.BID_BidRequestItemsList}
          viewType={ViewType.Grid}
        />
      )}
      {triggeredAction && viewId === ViewID.RequestClientApprovalValuerSelections && (
        <CustomActionHandler<BidRequestItem>
          updateAction={updateAction}
          refreshViewSet={refreshViewSet}
          actionFlag={ViewID.RequestClientApprovalValuerSelections}
          requestIdentifier="RequestClientApprovalValuerSelections Modal"
          dialogDetail="Are you sure you want to Send the Proposed Valuer(s) for Client Approval?"
          dialogTitle="Send the Proposed Valuer(s) for Client Approval"
          domainId={BidEngageDomains.BidRequestItem}
          viewId={ViewID.BID_BidRequestItemsList}
          viewType={ViewType.Grid}
        />
      )}
      {triggeredAction && viewId === ViewID.ResubmitProposedValuersforApproval && (
        <CustomActionHandler<BidRequestItem>
          updateAction={updateAction}
          refreshViewSet={refreshViewSet}
          actionFlag={ViewID.ResubmitProposedValuersforApproval}
          requestIdentifier="ResubmitProposedValuersforApproval Modal"
          dialogDetail="Are you sure you want to Resubmit the Proposed Valuer for Approval?"
          dialogTitle="Resubmit Proposed Valuers for Approval"
          domainId={BidEngageDomains.BidRequestItem}
          viewId={ViewID.BID_BidRequestItemsList}
          viewType={ViewType.Grid}
        />
      )}
      {triggeredAction && viewId === ViewID.ApproveProposedValuer && (
        <CustomActionHandler<BidRequestItem>
          updateAction={updateAction}
          refreshViewSet={refreshViewSet}
          actionFlag={ViewID.ApproveProposedValuer}
          requestIdentifier="ApproveProposedValuers Modal"
          dialogDetail="Are you sure you want accept the Proposed Valuer(s)?"
          dialogTitle="Approve Proposed Valuer(s)"
          domainId={BidEngageDomains.BidRequestItem}
          viewId={ViewID.BID_ProposedValuerApprovals}
          viewType={ViewType.Grid}
        />
      )}
      {triggeredAction && viewId === ViewID.DeclineProposedValuer && (
        <CustomActionHandler<BidRequestItem>
          updateAction={updateAction}
          refreshViewSet={refreshViewSet}
          actionFlag={ViewID.DeclineProposedValuer}
          requestIdentifier="DeclineProposedValuers Modal"
          dialogDetail="Are you sure you want reject the Proposed Valuer(s)?"
          dialogTitle="Decline Proposed Valuer(s)"
          domainId={BidEngageDomains.BidRequestItem}
          viewId={ViewID.BID_ProposedValuerApprovals}
          viewType={ViewType.Grid}
        />
      )}
      {triggeredAction && viewId === ViewID.ApproveProposedValuerLevel2 && (
        <CustomActionHandler<BidRequestItem>
          updateAction={updateAction}
          refreshViewSet={refreshViewSet}
          actionFlag={ViewID.ApproveProposedValuerLevel2}
          requestIdentifier="ApproveProposedValuersLevel2 Modal"
          dialogDetail="Are you sure you want accept the Proposed Valuer(s)?"
          dialogTitle="Approve Proposed Valuer(s)"
          domainId={BidEngageDomains.BidRequestItem}
          viewId={ViewID.BID_ProposedValuerApprovals}
          viewType={ViewType.Grid}
        />
      )}
      {triggeredAction && viewId === ViewID.DeclineProposedValuerLevel2 && (
        <CustomActionHandler<BidRequestItem>
          updateAction={updateAction}
          refreshViewSet={refreshViewSet}
          actionFlag={ViewID.DeclineProposedValuerLevel2}
          requestIdentifier="DeclineProposedValuersLevel2 Modal"
          dialogDetail="Are you sure you want reject the Proposed Valuer(s)?"
          dialogTitle="Decline Proposed Valuer(s)"
          domainId={BidEngageDomains.BidRequestItem}
          viewId={ViewID.BID_ProposedValuerApprovals}
          viewType={ViewType.Grid}
        />
      )}
      {triggeredAction && viewId === ViewID.SendInvitesToProposedValuers && (
        <CustomActionHandler<BidRequestItem>
          updateAction={updateAction}
          refreshViewSet={refreshViewSet}
          actionFlag={ViewID.SendInvitesToProposedValuers}
          requestIdentifier="SendInvites Modal"
          dialogDetail="Are you sure you want to Send Invites for the selected Request Items?"
          dialogTitle="Send Invites"
          domainId={BidEngageDomains.BidRequestItem}
          viewId={ViewID.SendInvitesToProposedValuers}
          viewType={ViewType.Grid}
        />
      )}
      {triggeredAction && viewId === ViewID.ApproveRecommendedValuer && (
        <CustomActionHandler<BidRequestItem>
          updateAction={updateAction}
          refreshViewSet={refreshViewSet}
          actionFlag={ViewID.ApproveRecommendedValuer}
          requestIdentifier="ApproveRecommendedValuer Modal"
          dialogDetail="Are you sure you want accept the Recommended Valuer(s)?"
          dialogTitle="Approve Recommended Valuer(s)"
          domainId={BidEngageDomains.BidRequestItem}
          viewId={ViewID.BID_RecommendedValuerApprovals}
          viewType={ViewType.Grid}
        />
      )}
      {triggeredAction && viewId === ViewID.DeclineRecommendedValuer && (
        <CustomActionHandler<BidRequestItem>
          updateAction={updateAction}
          refreshViewSet={refreshViewSet}
          actionFlag={ViewID.DeclineRecommendedValuer}
          requestIdentifier="DeclineRecommendedValuer Modal"
          dialogDetail="Are you sure you want reject the Recommended Valuer(s)?"
          dialogTitle="Decline Recommended Valuer(s)"
          domainId={BidEngageDomains.BidRequestItem}
          viewId={ViewID.BID_RecommendedValuerApprovals}
          viewType={ViewType.Grid}
        />
      )}
      {triggeredAction && viewId === ViewID.ApproveRecommendedValuerLevel2 && (
        <CustomActionHandler<BidRequestItem>
          updateAction={updateAction}
          refreshViewSet={refreshViewSet}
          actionFlag={ViewID.ApproveRecommendedValuerLevel2}
          requestIdentifier="ApproveRecommendedValuerLevel2 Modal"
          dialogDetail="Are you sure you want accept the Recommended Valuer(s)?"
          dialogTitle="Approve Recommended Valuer(s) Level 2"
          domainId={BidEngageDomains.BidRequestItem}
          viewId={ViewID.BID_RecommendedValuerLevel2Approvals}
          viewType={ViewType.Grid}
        />
      )}
      {triggeredAction && viewId === ViewID.DeclineRecommendedValuerLevel2 && (
        <CustomActionHandler<BidRequestItem>
          updateAction={updateAction}
          refreshViewSet={refreshViewSet}
          actionFlag={ViewID.DeclineRecommendedValuerLevel2}
          requestIdentifier="DeclineRecommendedValuerLevel2 Modal"
          dialogDetail="Are you sure you want reject the Recommended Valuer(s)?"
          dialogTitle="Decline Recommended Valuer(s) Level 2"
          domainId={BidEngageDomains.BidRequestItem}
          viewId={ViewID.BID_RecommendedValuerLevel2Approvals}
          viewType={ViewType.Grid}
        />
      )}
      {triggeredAction && viewId === ViewID.SetRequestToOnHold && (
        <CustomActionHandler<BidRequest>
          updateAction={updateAction}
          refreshViewSet={refreshViewSet}
          actionFlag={ViewID.SetRequestToOnHold}
          requestIdentifier="SetRequestToOnHold Modal"
          dialogDetail="Are you sure you want to put the Request on hold?"
          dialogTitle="Set Request to On Hold"
          domainId={BidEngageDomains.BidRequest}
          viewId={ViewID.SetRequestToOnHold}
          viewType={ViewType.Form}
          primaryKeyValue={primaryKeyValue}
        />
      )}
    </>
  );
};

export default CustomTriggerActions;
