export enum Status {
  Active = 1,
  Inactive = 0,
}

export enum ViewID {
  BidRequestModal = 'BID_BidRequestModal',
  SetProposedValuerForInternalReview = 'SetProposedValuerForInternalReview',
  SetProposedValuerForExternalReview = 'SetProposedValuerForExternalReview',
  RequestClientApprovalValuerSelections = 'RequestClientApprovalValuerSelections',
  ApproveProposedValuer = 'ApproveProposedValuer',
  DeclineProposedValuer = 'DeclineProposedValuer',
  ApproveProposedValuerLevel2 = 'ApproveProposedValuerLevel2',
  DeclineProposedValuerLevel2 = 'DeclineProposedValuerLevel2',
  ApproveRecommendedValuer = 'ApproveRecommendedValuer',
  DeclineRecommendedValuer = 'DeclineRecommendedValuer',
  ApproveRecommendedValuerLevel2 = 'ApproveRecommendedValuerLevel2',
  DeclineRecommendedValuerLevel2 = 'DeclineRecommendedValuerLevel2',
  ResubmitProposedValuersforApproval = 'ResubmitProposedValuersforApproval',
  SendInvitesToProposedValuers = 'SendInvitesToProposedValuers',
  BID_BidRequestItemsList = 'BID_BidRequestItemsList',
  BID_ProposedValuerApprovals = 'BID_ProposedValuerApprovals',
  BID_ProposedValuerLevel2Approvals = 'BID_ProposedValuerLevel2Approvals',
  BID_RecommendedValuerApprovals = 'BID_RecommendedValuerApprovals',
  BID_RecommendedValuerLevel2Approvals = 'BID_RecommendedValuerLevel2Approvals',
  SetRequestToOnHold = 'SetRequestToOnHold',
}

export enum BidEngageDomains {
  BidRequest = 'BID_BidRequest',
  BidRequestItem = 'BID_BidRequestItem',
}

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export interface BidEngageVM {
  Id: string;
  Name: string;
  DomainId: string;
}
