import React from 'react';
import { ConfirmationDialog, toastError, ToastMessage, toastSuccess } from '@samc/react-ui-core';
import { ViewSetAction, ViewType } from '@samc/screen-config-api/lib/model/ViewSet';
import { useSelectedRowsByGridContext } from '@samc/screen-config-core';
import { TaskRequest, TaskResponse, TaskStatus } from '@samc/screen-config-api';
import TaskResponsePopup from '@samc/screen-config-core/lib/molecules/TaskResponsePopup/TaskResponsePopup';
import { useApiContext } from '../../../hooks/useApiContext';
import { apiProvider } from '../../../BidEngageApi/api/Requests';
import { BidEngageDomains } from '../../../static/BidEngageConstants';

interface CustomActionHandlerPropsProps<T> {
  updateAction: (a: ViewSetAction | undefined) => void;
  refreshViewSet: () => void;
  actionFlag: keyof T;
  requestIdentifier: string;
  dialogDetail: string;
  dialogTitle: string;
  viewId: string;
  domainId: BidEngageDomains;
  viewType: ViewType;
  primaryKeyValue?: string;
}

const emptyResponsesArray: TaskResponse[] = [];
const defaultRequestsArray: TaskRequest[] = [
  {
    domainId: '',
    requestIdentifier: '',
    payload: {},
  },
];

export const CustomActionHandler = <T,>(props: CustomActionHandlerPropsProps<T>): React.ReactElement => {
  const {
    updateAction,
    refreshViewSet,
    actionFlag,
    requestIdentifier,
    dialogDetail,
    dialogTitle,
    viewId,
    domainId,
    viewType,
    primaryKeyValue,
  } = props;
  const { selectedRowsByGrids } = useSelectedRowsByGridContext(viewId);

  const { requestInit = {} } = useApiContext();

  const [requestResponse, setRequestResponse] = React.useState<{
    request: TaskRequest[];
    response: TaskResponse[];
  }>();
  const setVisible = React.useCallback((): void => setRequestResponse(undefined), []);

  const selectedRows = selectedRowsByGrids.flatMap((r) => r.selectedRows);

  const actionPayload = React.useMemo(() => {
    if (viewType === ViewType.Grid) {
      if (selectedRows.length === 0) {
        return {};
      }
      return selectedRows
        .filter((u): boolean => !u.__DELETED__)
        .reduce((acc, row): Record<string, T> => {
          return {
            ...acc,
            [`${row.Id}`]: {
              [actionFlag]: true,
            },
          } as Record<string, T>;
        }, {});
    }
    return {
      [`${primaryKeyValue}`]: {
        [actionFlag]: true,
      },
    } as unknown as Record<string, T>;
  }, [selectedRows, primaryKeyValue, actionFlag, viewType]);

  const submitForm = React.useCallback(
    async (overrideCodes?: string[]): Promise<void> => {
      const showError = (): void => {
        toastError(<ToastMessage title="Error" message="Something went wrong" />);
      };

      if (actionPayload === undefined) {
        toastError(<ToastMessage title="Error" message="There was an issue creating the payload." />);
        updateAction(undefined);
        return;
      }

      apiProvider
        .submitTask(
          domainId, // domainId
          domainId, // domainId
          requestIdentifier,
          actionPayload as TaskRequest['payload'],
          requestInit ?? {},
          false,
          undefined,
          overrideCodes,
        )
        .then(([response, request]) => {
          if (response.statusCode !== TaskStatus.Completed) {
            if (!overrideCodes) {
              setRequestResponse({ request: [request], response: [response] });
            }
          } else {
            toastSuccess(<ToastMessage title="Success" message="Save successful" />);
            updateAction(undefined);
            refreshViewSet();
          }
        })
        .catch(showError);
    },
    [requestInit, actionPayload],
  );

  const onResponse = (stay: boolean): void => {
    if (!stay) {
      updateAction(undefined);
    } else {
      submitForm();
    }
  };

  if (selectedRowsByGrids.length === 0 && viewType === ViewType.Grid) {
    toastError(<ToastMessage title="Error" message="At least one item in the grid must be selected to continue." />);
    updateAction(undefined);
    return <></>;
  }

  return (
    <>
      <ConfirmationDialog
        giveAnswer={onResponse}
        detail={dialogDetail}
        confirmText="Continue with Save"
        cancelText="Exit without saving"
        title={dialogTitle}
      />
      <TaskResponsePopup
        responses={requestResponse?.response ?? emptyResponsesArray}
        requests={requestResponse?.request ? requestResponse.request : defaultRequestsArray}
        visible={requestResponse?.response !== undefined}
        setVisible={setVisible}
        submitWithOverrides={submitForm}
      />
    </>
  );
};

export default CustomActionHandler;
