import React from 'react';

import {
  FormScreenWrapper,
  GridScreenWrapper,
  ServiceDrivenViewSet,
  useSelectedRowsByGridContext,
} from '@samc/screen-config-core';
import { Button, ControlBar, Loader, SitusHeader, toastError, ToastMessage, toastSuccess } from '@samc/react-ui-core';
import {
  EntityEntitlementContextProvider,
  TaskRequest,
  TaskResponse,
  TaskStatus,
  ViewSetAction,
  useViewSet,
} from '@samc/screen-config-api';
import TaskResponsePopup from '@samc/screen-config-core/lib/molecules/TaskResponsePopup/TaskResponsePopup';
import { ChangeDataArgs } from '@samc/react-ui-grid';
import { useStyletron } from 'styletron-react';
import { Modal } from '@fluentui/react';
import { useSearchParams } from 'react-router-dom';
import { FormScreenState } from '@samc/screen-config-core/lib/model/FormScreenState/FormScreenState';
import { useApiContext } from '../../../hooks/useApiContext';
import './AddBidRequest.scss';
import { apiProvider } from '../../../BidEngageApi/api/Requests';
import { BidRequestItem } from '../../../BidEngageApi/models/BidRequestItem';
import { BidRequest } from '../../../BidEngageApi/models/BidRequest';
import { useLiftAndSiftQuery } from '../../../hooks/useLiftAndSiftQuery/useLiftAndSiftQuery';
import { GetCurrentMonthAsName } from '../../../functions/GetCurrentMonthAsName/GetCurrentMonthAsName';

const emptyResponsesArray: TaskResponse[] = [];
const defaultRequestsArray: TaskRequest[] = [
  {
    domainId: '',
    requestIdentifier: '',
    payload: {},
  },
];

const targetGridContext = 'BidGroupManagement';
const bidRequestDomainId = 'BID_BidRequest';
const bidRequestItemDomainId = 'BID_BidRequestItem';

const NEW_BIDREQUEST: BidRequest = {
  Id: '-1',
  Name: '',
  BidWindowStart: '',
  BidWindowEnd: '',
  BidWindowEndTime: '',
  BidWindowEndTimezone: '',
  BidRequestStatusCode: '',
  NumYearsCycle: '',
  DiscountNoticeText: '',
  BidRequestNote: '',
  IncludeReviewProposalsInternally: false,
  IncludeReviewProposalsExternally: false,
  IncludeApproveProposedValuers: false,
  IncludeApproveProposedValuersLevel2: false,
  IncludeReviewRecommendationInternally: false,
  IncludeReviewRecommendationExternally: false,
  IncludeApproveRecommendationValuers: false,
  IncludeApproveRecommendationValuersLevel2: false,
  ELAddendumAText: '',
  ELAddendumBText: '',
  ELClientLongName: '',
  ShortName: '',
  Portfolio_Id: '',
  __ADDED__: true,
  __CHILD_REQUESTS__: [],
};

const requestInfoFormDefaultData: Record<string, unknown> = {
  Name: '',
  BidWindowStart: '',
  BidWindowEnd: '',
  BidWindowEndTime: '',
  BidWindowEndTimezone: '',
  BidRequestStatusCode: '',
  NumYearsCycle: '',
  DiscountNoticeText: '',
  BidRequestNote: '',
  IncludeReviewProposalsInternally: null,
  IncludeReviewProposalsExternally: null,
  IncludeApproveProposedValuers: null,
  IncludeApproveProposedValuersLevel2: null,
  IncludeReviewRecommendationInternally: null,
  IncludeReviewRecommendationExternally: null,
  IncludeApproveRecommendationValuers: null,
  IncludeApproveRecommendationValuersLevel2: null,
  ELAddendumAText: '',
  ELAddendumBText: '',
  ELClientLongName: '',
};

interface AddBidRequestProps {
  viewSetId: string;
  primaryKeyValue: string | undefined;
  updateAction: (a: ViewSetAction | undefined) => void;
  refreshViewSet: () => void;
}

export const AddBidRequest = ({
  viewSetId,
  primaryKeyValue,
  updateAction,
  refreshViewSet,
}: AddBidRequestProps): React.ReactElement => {
  const result = useViewSet(viewSetId);
  const formViewResult = result.data?.tabs[0];
  let name: BidRequestItem;
  const [bidRequestPayload, setBidRequestPayload] = React.useState<BidRequest>(NEW_BIDREQUEST);
  const [isInitBidRequestPayload, setIsInitBidRequestPayload] = React.useState(false);
  const [formState, setFormState] = React.useState<FormScreenState>();
  const [isOpen, toggleModal] = React.useState<boolean>(true);

  const api = useApiContext();
  const [params] = useSearchParams();

  const portfolioId = params.get('PortfolioId');

  const { selectedRowsByGrids, setSelectedRowsByGrids } = useSelectedRowsByGridContext(targetGridContext);

  const [selectedRows, setSelectedRows] = React.useState<Record<string, BidRequestItem>[]>(
    (selectedRowsByGrids[0]?.selectedRows ?? []) as Record<string, BidRequestItem>[],
  );

  const selectedIds: string = selectedRowsByGrids[0]?.selectedRows.map((row) => row.Id).join();

  const closeModal = (): void => {
    toggleModal(false);
    updateAction(undefined);
  };

  const [requestResponse, setRequestResponse] = React.useState<{
    request: TaskRequest[];
    response: TaskResponse[];
  }>();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const noOp = (): void => {};

  const setVisible = React.useCallback((): void => setRequestResponse(undefined), []);
  const bidRequestItemPayload = React.useMemo(
    () =>
      selectedRows
        .filter((u): boolean => !u.__DELETED__)
        .reduce((acc, _row, index): Record<string, BidRequestItem> => {
          const rowValues = Object.values(selectedRows)[index];
          let assetId;
          let valuationGroupId;

          if (selectedRowsByGrids[0].gridId === 'BID_UngroupedValuationGroups') {
            valuationGroupId = rowValues.Id;
            name = rowValues.Name;
          }

          if (selectedRowsByGrids[0].gridId === 'BID_UngroupedAssets') {
            assetId = rowValues.Id;
            name = rowValues.Name;
          }

          if (selectedRowsByGrids[0].gridId === 'BID_BidGroupItemsList') {
            valuationGroupId = rowValues.ValuationGroupId;
            assetId = rowValues.AssetId;
            name = rowValues.BidGroup_Name;
          }
          requestInfoFormDefaultData.Name = `${name}  (${GetCurrentMonthAsName()} ${new Date().getFullYear()})`;

          return {
            ...acc,
            [`${-index - 1}`]: {
              Portfolio_Id: rowValues.Portfolio_Id,
              AssetId: assetId ? String(assetId) : undefined,
              ValuationGroupId: valuationGroupId ? String(valuationGroupId) : undefined,
              Name: name,
              __ADDED__: true,
            },
          } as Record<string, BidRequestItem>;
        }, {}),
    [selectedRows],
  );

  const submitForm = React.useCallback(
    async (overrideCodes?: string[]): Promise<void> => {
      bidRequestPayload.Id = '-1';
      bidRequestPayload.Portfolio_Id = portfolioId ?? '';
      bidRequestPayload.__ADDED__ = true;
      const bidRequestItemTask = {
        domainId: 'BID_BidRequestItem',
        fieldConfigurationId: 'BID_BidRequestItem',
        viewId: '',
        payload: bidRequestItemPayload as unknown as TaskRequest['payload'],
      };
      bidRequestPayload.__CHILD_REQUESTS__ = [bidRequestItemTask];
      const payload = { '-1': bidRequestPayload } as unknown as TaskRequest['payload'];

      const showError = (): void => {
        toastError(<ToastMessage title="Error" message="Something went wrong" />);
      };

      apiProvider
        .submitTask(
          'BID_BidRequest',
          'BID_BidRequest',
          'BID_BidRequest Form',
          payload,
          api.requestInit ?? {},
          false,
          undefined,
          overrideCodes,
        )
        .then(([response, request]) => {
          if (response.statusCode !== TaskStatus.Completed) {
            if (!overrideCodes) {
              setRequestResponse({ request: [request], response: [response] });
            }
          } else {
            toastSuccess(<ToastMessage title="Success" message="Bid Request saved successfully" />);
          }

          toggleModal(false);
          updateAction(undefined);
          setSelectedRows([]); // Resets grid selection data to ensure form is not dirty anymore
          refreshViewSet();
        })
        .catch(showError);
    },
    [api.requestInit, bidRequestItemPayload, bidRequestPayload],
  );

  const cancel = (): void => {
    setSelectedRowsByGrids([]);
    toggleModal(false);
    updateAction(undefined);
    refreshViewSet();
  };

  const onChangeData = React.useCallback(
    (args: ChangeDataArgs<Record<string, unknown>>) => {
      const changedData = Object.values(args.changedData);
      const rows = selectedRows.filter((row): boolean => !changedData.some((r) => r.Id === Object.values(row)[0].Id));
      setSelectedRows([...rows]);
    },
    [setSelectedRows, selectedRows],
  );

  const handleFormChange = React.useCallback(
    (data: unknown): void => {
      setBidRequestPayload(data as BidRequest);
    },
    [setBidRequestPayload],
  );

  const handleFormStateChange = React.useCallback(
    (state: FormScreenState): void => {
      setFormState(state);
    },
    [setFormState],
  );

  const portfolioSettings = useLiftAndSiftQuery<Record<string, unknown>>(`key`, {
    domainId: 'BID_PortfolioSettings',
    primaryViewId: '-1',
    filterIds: [],
    paging: {
      start: 0,
      stop: 100,
    },
    adhocListViewMembers: [
      { scalarExpression: '[BidWindowStart]', scalarDisplayName: 'BidWindowStart' },
      { scalarExpression: '[BidWindowEnd]', scalarDisplayName: 'BidWindowEnd' },
      { scalarExpression: '[BidWindowEndTime]', scalarDisplayName: 'BidWindowEndTime' },
      { scalarExpression: '[BidWindowTimezone]', scalarDisplayName: 'BidWindowEndTimezone' },
      { scalarExpression: '[BidEngageRequestNotes]', scalarDisplayName: 'BidEngageRequestNotes' },
      { scalarExpression: '[YearsInCycle]', scalarDisplayName: 'NumYearsCycle' },
      { scalarExpression: '[BidEngageDiscountText]', scalarDisplayName: 'BidEngageDiscountText' },
      { scalarExpression: '[IncludeReviewProposalsInternally]', scalarDisplayName: 'IncludeReviewProposalsInternally' },
      { scalarExpression: '[IncludeReviewProposalsExternally]', scalarDisplayName: 'IncludeReviewProposalsExternally' },
      { scalarExpression: '[IncludeApproveProposedValuers]', scalarDisplayName: 'IncludeApproveProposedValuers' },
      {
        scalarExpression: '[IncludeApproveProposedValuersLevel2]',
        scalarDisplayName: 'IncludeApproveProposedValuersLevel2',
      },
      {
        scalarExpression: '[IncludeReviewRecommendationInternally]',
        scalarDisplayName: 'IncludeReviewRecommendationInternally',
      },
      {
        scalarExpression: '[IncludeReviewRecommendationExternally]',
        scalarDisplayName: 'IncludeReviewRecommendationExternally',
      },
      {
        scalarExpression: '[IncludeApproveRecommendationValuers]',
        scalarDisplayName: 'IncludeApproveRecommendationValuers',
      },
      {
        scalarExpression: '[IncludeApproveRecommendationValuersLevel2]',
        scalarDisplayName: 'IncludeApproveRecommendationValuersLevel2',
      },
      {
        scalarExpression: '[ELAddendumAText]',
        scalarDisplayName: 'ELAddendumAText',
      },
      {
        scalarExpression: '[ELAddendumBText]',
        scalarDisplayName: 'ELAddendumBText',
      },
      {
        scalarExpression: '[ELClientLongName]',
        scalarDisplayName: 'ELClientLongName',
      },
    ],
    sorting: {
      order: 'asc',
      orderBy: { scalarExpression: '[BidEngageRequestNotes]' },
    },
    summaryMode: false,
    adhocFilter: {
      filterName: 'adhoc',
      advancedInd: true,
      expressionLang: 'Centric',
      advancedExpression: `[Portfolio_Id] = '${portfolioId}'`,
    },
  });

  const adjustDateToFollowingMonday = (date: Date): Date => {
    const dayOfWeek = date.getDay();
    if (dayOfWeek === 6) {
      date.setDate(date.getDate() + 2);
    }

    if (dayOfWeek === 0) {
      date.setDate(date.getDate() + 1);
    }

    return date;
  };

  const getDates = ({
    bidWindowStart,
    bidWindowEnd,
  }: {
    bidWindowStart: unknown;
    bidWindowEnd: unknown;
  }): { bidWindowStartDate: Date; bidWindowEndDate: Date } => {
    const bidWindowStartDate: Date = new Date();
    let bidWindowEndDate: Date = new Date();

    bidWindowStartDate.setDate(bidWindowStartDate.getDate() + Number(bidWindowStart));
    bidWindowEndDate.setDate(bidWindowStartDate.getDate() + Number(bidWindowEnd));
    const isEndDateOnWeekend = bidWindowEndDate.getDay() === 6 || bidWindowEndDate.getDay() === 0;

    if (isEndDateOnWeekend) {
      bidWindowEndDate = adjustDateToFollowingMonday(bidWindowEndDate);
    }

    return { bidWindowStartDate, bidWindowEndDate };
  };

  const setRequestInfoFormDefaultData = ({
    portfolioSettingsData,
  }: {
    portfolioSettingsData: Record<string, unknown> | undefined;
  }): void => {
    if (!portfolioSettingsData) return;

    const { bidWindowStartDate, bidWindowEndDate }: { bidWindowStartDate: Date; bidWindowEndDate: Date } = getDates({
      bidWindowStart: portfolioSettingsData.BidWindowStart,
      bidWindowEnd: portfolioSettingsData.BidWindowEnd,
    });

    requestInfoFormDefaultData.BidWindowStart = bidWindowStartDate;
    requestInfoFormDefaultData.BidWindowEnd = bidWindowEndDate;
    requestInfoFormDefaultData.BidWindowEndTime = portfolioSettingsData?.BidWindowEndTime;
    requestInfoFormDefaultData.BidWindowEndTimezone = portfolioSettingsData?.BidWindowEndTimezone;
    requestInfoFormDefaultData.BidRequestStatusCode = portfolioSettingsData?.BidRequestStatusCode;
    requestInfoFormDefaultData.NumYearsCycle = portfolioSettingsData?.NumYearsCycle;
    requestInfoFormDefaultData.Portfolio_Id = portfolioId;
    requestInfoFormDefaultData.DiscountNoticeText = portfolioSettingsData?.BidEngageDiscountText;
    requestInfoFormDefaultData.BidRequestNote = portfolioSettingsData?.BidEngageRequestNotes;
    requestInfoFormDefaultData.IncludeReviewProposalsInternally =
      portfolioSettingsData?.IncludeReviewProposalsInternally;
    requestInfoFormDefaultData.IncludeReviewProposalsExternally =
      portfolioSettingsData?.IncludeReviewProposalsExternally;
    requestInfoFormDefaultData.IncludeApproveProposedValuers = portfolioSettingsData?.IncludeApproveProposedValuers;
    requestInfoFormDefaultData.IncludeApproveProposedValuersLevel2 =
      portfolioSettingsData?.IncludeApproveProposedValuersLevel2;
    requestInfoFormDefaultData.IncludeReviewRecommendationInternally =
      portfolioSettingsData?.IncludeReviewRecommendationInternally;
    requestInfoFormDefaultData.IncludeReviewRecommendationExternally =
      portfolioSettingsData?.IncludeReviewRecommendationExternally;
    requestInfoFormDefaultData.IncludeApproveRecommendationValuers =
      portfolioSettingsData?.IncludeApproveRecommendationValuers;
    requestInfoFormDefaultData.IncludeApproveRecommendationValuersLevel2 =
      portfolioSettingsData?.IncludeApproveRecommendationValuersLevel2;
    requestInfoFormDefaultData.ELAddendumAText = portfolioSettingsData?.ELAddendumAText;
    requestInfoFormDefaultData.ELAddendumBText = portfolioSettingsData?.ELAddendumBText;
    requestInfoFormDefaultData.ELClientLongName = portfolioSettingsData?.ELClientLongName;

    if (!isInitBidRequestPayload) {
      const defaultBidRequestPayload: BidRequest = {
        Id: '-1',
        BidWindowStart: bidWindowStartDate as unknown as string,
        BidWindowEnd: bidWindowEndDate as unknown as string,
        BidWindowEndTime: portfolioSettingsData?.BidWindowEndTime as string,
        BidWindowEndTimezone: portfolioSettingsData?.BidWindowEndTimezone as string,
        NumYearsCycle: portfolioSettingsData?.NumYearsCycle as string,
        Name: requestInfoFormDefaultData.Name as string,
        BidRequestStatusCode: requestInfoFormDefaultData.BidRequestStatusCode as string,
        DiscountNoticeText: requestInfoFormDefaultData.DiscountNoticeText as string,
        BidRequestNote: requestInfoFormDefaultData.BidRequestNote as string,
        ShortName: requestInfoFormDefaultData.Name as string,
        Portfolio_Id: portfolioId as string,
        IncludeReviewProposalsInternally: requestInfoFormDefaultData.IncludeReviewProposalsInternally as boolean,
        IncludeReviewProposalsExternally: requestInfoFormDefaultData.IncludeReviewProposalsExternally as boolean,
        IncludeApproveProposedValuers: requestInfoFormDefaultData.IncludeApproveProposedValuers as boolean,
        IncludeApproveProposedValuersLevel2: requestInfoFormDefaultData.IncludeApproveProposedValuersLevel2 as boolean,
        IncludeReviewRecommendationInternally:
          requestInfoFormDefaultData.IncludeReviewRecommendationInternally as boolean,
        IncludeReviewRecommendationExternally:
          requestInfoFormDefaultData.IncludeReviewRecommendationExternally as boolean,
        IncludeApproveRecommendationValuers: requestInfoFormDefaultData.IncludeApproveRecommendationValuers as boolean,
        IncludeApproveRecommendationValuersLevel2:
          requestInfoFormDefaultData.includeApproveRecommendationValuersLevel2 as boolean,
        ELAddendumAText: requestInfoFormDefaultData.ELAddendumAText as string,
        ELAddendumBText: requestInfoFormDefaultData.ELAddendumBText as string,
        ELClientLongName: requestInfoFormDefaultData.ELClientLongName as string,
        __ADDED__: true,
        __CHILD_REQUESTS__: [],
      };
      setBidRequestPayload(defaultBidRequestPayload);
      setIsInitBidRequestPayload(true);
    }
  };

  const portfolioSettingsData: Record<string, unknown> | undefined = portfolioSettings.data?.Data[0];
  setRequestInfoFormDefaultData({ portfolioSettingsData });

  const [css] = useStyletron();

  return (
    <>
      <Modal isOpen={isOpen} onDismiss={closeModal} isBlocking={false} className="bidRequestModal">
        <div
          className="view-modal"
          style={{
            height: '85vh',
            width: '85vw',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#f8f8f8',
          }}
        >
          <SitusHeader title="Create Bid Request From Selected">
            <ControlBar
              cancel={cancel}
              cancelDisabled={false}
              additionalControls={
                <Button
                  text="Save and Close"
                  disabled={!formState?.isValid || selectedRows.length === 0}
                  onClick={(): void => {
                    submitForm();
                  }}
                />
              }
            />
          </SitusHeader>
          <Loader isLoading={result.isLoading} isError={result.isError}>
            <>
              <EntityEntitlementContextProvider
                domainId={bidRequestDomainId}
                entityId={primaryKeyValue}
                targetEntitlementTypes={[]}
              >
                <FormScreenWrapper
                  className={css({ overflow: 'visible' })}
                  formViewId={formViewResult?.id ?? ''}
                  onChange={(data): void => handleFormChange(data)}
                  onFormStateChange={(state): void => handleFormStateChange(state)}
                  GridScreenWrapper={GridScreenWrapper}
                  ServiceDrivenViewSet={ServiceDrivenViewSet}
                  defaultData={requestInfoFormDefaultData}
                />
              </EntityEntitlementContextProvider>
              <div className="gridSection">
                <EntityEntitlementContextProvider
                  domainId={bidRequestItemDomainId}
                  entityId={primaryKeyValue}
                  targetEntitlementTypes={[]}
                >
                  <GridScreenWrapper
                    style={{ height: '100%' }}
                    listViewId={selectedRowsByGrids[0].gridId ?? ''}
                    onChangeData={onChangeData}
                    filters={[`DoesContain([Id], '${selectedIds}')`]}
                    onSubmit={noOp}
                    ServiceDrivenViewSet={ServiceDrivenViewSet}
                  />
                </EntityEntitlementContextProvider>
              </div>
            </>
          </Loader>
        </div>
      </Modal>
      <TaskResponsePopup
        responses={requestResponse?.response ?? emptyResponsesArray}
        requests={requestResponse?.request ? requestResponse.request : defaultRequestsArray}
        visible={requestResponse?.response !== undefined}
        setVisible={setVisible}
        submitWithOverrides={submitForm}
      />
    </>
  );
};

export default AddBidRequest;
